$( document ).ready(function() {
 if (screen.width > 767) { 
   $(".team-tab").css({'height': $('.team-tab-content.active').innerHeight()}); 

        $(".team-tab-menu>.list-team>a").click(function(e) {
        e.preventDefault();
        $(this).siblings('a.active').removeClass("active");
        $(this).addClass("active");
        var index = $(this).index();
        
        $(".team-tab>.team-tab-content").removeClass("active");
        $(".team-tab>.team-tab-content").eq(index).addClass("active");

        var boxHeight = $(".team-tab-content").eq(index).find(".no-gutters").innerHeight();
        $(".team-tab").height(boxHeight + "px");
    }); 


        $(".team-tab-menu>.list-team>a").hover(function(e) {
        e.preventDefault();
        $(this).siblings('a.active').removeClass("active");
        $(this).addClass("active");
        var index = $(this).index();
        
        $(".team-tab>.team-tab-content").removeClass("active");
        $(".team-tab>.team-tab-content").eq(index).addClass("active");

        var boxHeight = $(".team-tab-content").eq(index).find(".no-gutters").innerHeight();
        $(".team-tab").height(boxHeight + "px");
    }); 
 }
    //Navigation Scrolling
    $('.nav-left li a').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });



//parallax effect
  
    $(document).scroll(function() {
        var st1 = $(this).scrollTop();
        $("#home").css({
            "background-position-y": -(st1 / 10)
        });
        
    });

    //Adding fixed position to header
    $(document).scroll(function() {
        if ($(document).scrollTop() >= 1) {
            $('.topnav').addClass('fixed-top');
            $('html').addClass('has-fixed-nav');

        } else {
            $('.topnav').removeClass('fixed-top');
            $('html').removeClass('has-fixed-nav');

        }
        
    });
//owl-carousel distinction
if ($('.dist-carousel').length > 0) { 
 $('.dist-carousel').owlCarousel({
    items:1,
    lazyLoad:true,
    loop:true,
    margin:0 
});
}
 //owl-carousel home team
 if (screen.width < 767) {  
$('.team-tab').addClass('owl-carousel');  
 $('.team-tab').owlCarousel({
    items:1,
    lazyLoad:true,
    loop:true,
    margin:0,
    autoHeight:true 
});
}
 if (screen.width < 576) { 
    $('.photo-team').addClass('order-first');
}
// animation scroll
if ($('.right-box-anim').length > 0) { 
    AOS.init({ 
        easing: 'ease-out-back',
        duration: 1000
    }); 
}

if ($('.selectpicker').length > 0) {
    $('.selectpicker').selectpicker();
}
  //Navigation Menu Slider
    $('.contact-btn').click(function(e) {
        e.preventDefault();
        $('.contact-popup').toggleClass('open');  
        $(this).fadeOut();
    });
 $('.contact-close').click(function(e) {
        e.preventDefault(); 
       $(this).closest('.inner-contact').find('.contact-btn').show(); 
        $(this).parent().removeClass('open'); 
    });
$('.box-action-btn .postuler-btn').click(function(e) {
        e.preventDefault();
        $('.top-condidtaure').slideToggle ();  
    });

//serach block heaader
$('.search-btn').click(function(e) {
        e.preventDefault();
        $('.block-searche-filter').slideToggle (); 
        $('html').toggleClass ('filter-cover');   
    });
    $('.colse-btn').click(function(e) {
        e.preventDefault();
        $('.block-searche-filter').slideUp (); 
        $('html').removeClass ('filter-cover');  

    });

// Accordion
	/*$(".clients-ref .titre-acc:first").addClass("active");
	$(".clients-ref .content-acc:not(:first)").hide();*/
	$(".clients-ref .content-acc ").hide();
	
	$(".clients-ref .titre-acc").click(function(){
		$(this).next(".content-acc").slideToggle()
		.siblings(".content-acc:visible").slideUp();
		$(this).toggleClass("active");
		$(this).siblings(".titre-acc").removeClass("active");
	});
    
 //add class input
$('input.input-lg, textarea.input-lg').on('keyup', function (ev) {
    $(this).toggleClass('active', !!$(this).val().trim());
});
$('#nous-joindre .selectpicker').change(function(){
    $(this).parent().addClass('active');
});

//height box equipe
$('.equipe .inner-equipe').matchHeight();


 //var sidebar = new StickySidebar('.nav-left', {
       // containerSelector: '.section-left',
       // innerWrapperSelector: '.nav-left ul',
       // topSpacing: 90,
       // bottomSpacing: 20
   // });

//$(document).scroll(function(e){ 
  //var $el = $('.nav-left'); 
 // var isPositionFixed = ($el.css('position') == 'fixed');
 // if ($(this).scrollTop() > 200 && !isPositionFixed){ 
 //   $el.addClass('stiky-nav'); 
//  }
 // if ($(this).scrollTop() < 300 && isPositionFixed){
  //  $el.removeClass('stiky-nav');
//  } 
//});

$(document).scroll(function(e){ 
 var window_top = $(window).scrollTop();
  var div_top = $('.section-left').offset().top-100; 
  if (window_top > div_top) {
    $('.nav-left ul').addClass('stiky-nav');
  } else {
        if (window_top < div_top) {
            $('.nav-left ul').removeClass('stiky-nav');
        }
    }
 
});


  //Nav Selection
  $('.nav-left ul').onePageNav({
    currentClass: 'active',
    scrollOffset: 100
  });


});